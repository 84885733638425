<template>
  <main class="layout-with-bar documents">
    <div class="layout-with-bar__main documents__main">
      <CustomSelect
        v-model="selectedFilterType"
        class="document-return__select-filter"
        label-option-name="title"
        :options="filterTypeOptions"
        style-light
      />
      <div class="documents-items__wrapper">
        <div v-for="document in documents" :key="document.id" class="documents-item">
          <p class="documents-item__title">
            {{ getDocumentTypeName(document.type) }}
          </p>
          <p class="documents-item__name">
            {{ document.name }}
          </p>
          <div class="documents-item__footer">
            <!-- <button @click="downloadDocument(document)">
              СКАЧАТЬ
            </button> -->
            <a
              class="documents-item__download"
              target="_blank"
              :href="generateFileOriginalPath(document.file_id)"
              download
            >
              СКАЧАТЬ
            </a>
            <p class="documents-item__file-info">
              {{ getDocumentSize(document.size) }}, .{{ document.ext }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import CustomSelect from '@/components/elements/CustomSelect';
import { generateFileOriginalPath } from '@/plugins/helpers/file';
import pageMeta from '~/mixins/pageMeta';

export default {
  name: 'ProfileDocuments',
  components: {
    CustomSelect,
  },
  mixins: [pageMeta],
  asyncData() {
    return {
      pageTitle: 'Мои документы',
    };
  },
  data() {
    return {
      documents: [],
      filterTypeOptions: [
        {
          title: 'Все типы',
          type: null,
        },
        {
          title: 'Контракты',
          type: 1,
        },
        {
          title: 'Отчеты',
          type: 2,
        },
        {
          title: 'Акты',
          type: 3,
        },
      ],
      selectedFilterType: {
        title: 'Все типы',
        value: null,
      },
    };
  },
  async fetch() {
    await this.getFilteredDocuments(this.selectedFilterType);
  },
  watch: {
    async selectedFilterType(value) {
      await this.getFilteredDocuments(value);
    },
  },
  methods: {
    generateFileOriginalPath,
    async getFilteredDocuments(payload) {
      const { documents } = await this.$api.profile.getProfileDocuments(payload);

      this.documents = documents;
    },
    getDocumentTypeName(id) {
      switch (id) {
        case 1:
          return 'Контракты';
        case 2:
          return 'Отчеты';
        case 3:
          return 'Акты';
      }
    },
    getDocumentSize(bytes) {
      const KB = bytes / 1024

      if (KB < 1024) {
        return this.getSize(KB, 'KB')
      }

      return this.getSize(KB / 1024, 'MB')
    },
    getSize(size, type) {
      return `${size.toFixed(2)} ${type}`
    },
  },
};
</script>
